<template>
  <div>
    <WhoWeAre />
    <b-container>
      <h2 class="font-bold text-center mb-5">
        {{ $t("title.our_companies") }}
      </h2>
      <b-row>
        <template v-if="!slides.length">
          <b-col
            class="mb-2"
            md="3"
            v-for="shimmer in shimmers"
            :key="shimmer.index"
          >
            <content-placeholders :rounded="true">
              <content-placeholders-img /> </content-placeholders
          ></b-col>
        </template>
        <b-col v-else md="12" lg="10" class="mx-auto">
          <b-row>
            <b-col
              v-for="slide in slides"
              :key="slide.index"
              lg="3"
              md="4"
              sm="6"
            >
              <CompanyDetails class="my-3" :slide="slide" />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" class="text-center">
          <b-btn
            @click="overlayToggle"
            variant="default"
            class="router-link mt-5 mx-auto"
            ><span>{{ $t("button.register") }}</span>
          </b-btn>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { IndexData } from "@/helpers/apiMethods";

export default {
  name: "OurCompanies",
  data() {
    return {
      slides: [],
      shimmers: 8
    };
  },
  mounted() {
    this.handleGetCompanies();
  },
  components: {
    WhoWeAre: () => import("@/components/Home/WhoWeAre"),
    CompanyDetails: () => import("@/components/Home/OurCompanies/SingleCompany")
  },
  methods: {
    async handleGetCompanies() {
      try {
        const companies = await IndexData({ reqName: "companies" });
        this.slides = companies.data.companies;
      } catch (err) {
        console.log(err);
      }
    },
    overlayToggle() {
      this.$store.dispatch("SET_OVERLAY");
    }
  }
};
</script>

<style lang="scss" scoped></style>
